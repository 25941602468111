export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: '掲示板',
        to: '/',
        icon: 'cil-speedometer',
      },
      {
        _name: 'CSidebarNavDropdown',
        name: '商品',
        route: '/products',
        icon: 'cil-barcode',
        items: [
          {
            name: 'Product List',
            to: {name: 'Products'}
          },
          {
            name: '出品',
            to: {name: "ProductCreate"}
          },
        ]
      },
      {
        _name: 'CSidebarNavItem',
        name: '注文',
        to: {name: 'Orders'},
        icon: 'cil-basket'
      }
    ]
  }
]
