<template>
  <div class="c-app">
    <TheSidebar />
    <CWrapper>
      <TheHeader />
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path"></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
      <TheFooter />
    </CWrapper>
  </div>
</template>

<script>
import TheSidebar from "./TheSidebar";
import TheHeader from "./TheHeader";
import TheFooter from "./TheFooter";

export default {
  name: "TheContainer",
  components: {
    TheSidebar,
    TheHeader,
    TheFooter,
  },

  mounted() {
    let loader = this.$loading.show({
      // Optional parameters
      canCancel: false
    });

    // Check authenticated and redirect
    this.$store.dispatch('auth/checkAuthenticated')
        .then(res => {
          let {success} = res.data;

          loader.hide();

          if (!success) {
            this.$router.push({name: 'login'});
          }
        });
  },

  metaInfo: {
    titleTemplate: "%s - Poste Ecomerce Platform",
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
