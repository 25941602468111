<template>
  <CDropdown
      inNav
      class="c-header-nav-items"
      placement="bottom-end"
      add-menu-classes="pt-0 mt-1"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">
          <img
              src="/img/avatars/6.jpg"
              class="c-avatar-img "
          />
        </div>
      </CHeaderNavLink>
    </template>

    <CDropdownItem :to="{ name: 'Me' }">
      <CIcon name="cil-user"/>
      Profile
    </CDropdownItem>

    <CDropdownDivider/>
    <CDropdownItem @click="doLogout">
      <CIcon name="cil-lock-locked" /> Logout
    </CDropdownItem>
  </CDropdown>
</template>

<script>
export default {
  name: 'TheHeaderDropdownAccnt',
  data () {
    return {
      itemsCount: 42
    }
  },

  methods: {
    doLogout() {
      let loader = this.$loading.show({
        // Optional parameters
        canCancel: false
      });

      this.$store.dispatch('auth/doLogout')
      .then(() => {
        this.$router.push({ name: 'login' });
      })
      .finally(() => {
        loader.hide();
      });
    }
  }
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>
