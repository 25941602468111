export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: '掲示板',
        to: '/',
        icon: 'cil-speedometer',
      },
      {
        _name: 'CSidebarNavDropdown',
        name: '商品',
        route: '/products',
        icon: 'cil-barcode',
        items: [
          {
            name: 'カテゴリー',
            to: {name: 'ProductCategories'}
          }
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: '店舗',
        route: '/shops',
        icon: 'cil-bank',
        items: [
          {
            name: '店舗リスト',
            to: {name: 'Shops'}
          },
          {
            name: 'Create Shop',
            to: {name: 'ShopCreate'}
          },
          {
            name: 'カテゴリー',
            to: {name: 'ShopCategories'}
          },
          {
            name: 'オーナー情報',
            to: {name: 'Owners'}
          }
        ]
      },
      {
        _name: 'CSidebarNavItem',
        name: '注文',
        to: {name: 'Orders'},
        icon: 'cil-basket'
      }
    ]
  }
]
