<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <CImg class="c-sidebar-brand-full" :src="require('../assets/logo.svg')" :height="35" />
      <CImg class="c-sidebar-brand-minimized" :src="require('../assets/logo.svg')" :height="35" />
    </CSidebarBrand>

    <CRenderFunction flat :content-to-render="getNav"/>
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>

import {mapState} from "vuex";
import _nav_owner from "./_nav_owner";
import {USER_ROLE_OWNER} from "../utils/constants";
import _nav from "./_nav";

export default {
  name: 'TheSidebar',

  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),

    show() {
      return this.$store.state.sidebarShow
    },
    minimize() {
      return this.$store.state.sidebarMinimize
    },
    getNav() {
      if (!this.user || this.user.role_id === USER_ROLE_OWNER) {
        return _nav_owner;
      }

      return _nav;
    }
  }
}
</script>
